.app.dark {
  background: #141d26;
  color: #fff;
  transition: all 0.2s ease;

  .sidebar,
  .navbar {
    background: #243447;
    transition: all 0.2s ease;
  }

  .sidebar {
    border-right: 0.5px solid rgb(48 48 48);
    transition: all 0.2s ease;

    hr {
      border: 0.5px solid rgb(48 48 48);
      border-bottom: none;
      transition: all 0.2s ease;
    }
  }

  .navbar {
    border-bottom: 0.5px solid rgb(48 48 48);
    transition: all 0.2s ease;
  }

  .logo,
  .dark-mode-icon,
  .refresh-list {
    color: #fff;
    transition: all 0.2s ease;
  }

  text,
  .search-field > *,
  .audits .search-close-icon {
    color: #fff;
    fill: #fff;
    transition: all 0.2s ease;
  }

  .search-field:active,
  .search-field :focus {
    color: rgb(87, 87, 241);
  }

  span,
  input::placeholder,
  .audits .icon {
    color: #fff !important;
    transition: all 0.2s ease;
  }

  .data-table-container nav span {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .bu-flags > *,
  .browse .data-extension > *,
  .browse .data-extension span,
  .browse .data-extension input::placeholder,
  .browse .ruleGroup select,
  .browse .ruleGroup input,
  .audits .de-dependency span {
    color: #222 !important;
  }

  .filter-icon {
    color: rgba(0, 0, 0, 0.54);
  }

  .audits text {
    fill: #222;
  }

  .card,
  .audits {
    background: #243447;
    box-shadow: rgb(62 62 62 / 20%) 0px 2px 8px 0px;
    transition: all 0.2s ease;
  }
}

.app {
  transition: all 0.2s ease;
}
