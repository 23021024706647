.audits {
  // background: #fff;
  // border-radius: 0.25rem;
  // box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
  padding: 16px;

  // .MuiAccordionSummary-expandIconWrapper {
  //   position: absolute;
  //   left: 17px;
  // }

  a {
    color: #0070d2 !important;
    transition: all 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    // width: 100%;
    g.node {
      cursor: pointer;
      display: inline-block;
      -ms-user-select: none;
      -webkit-user-select: none;
      user-select: none;

      ellipse {
        fill: lightpink;
      }

      path,
      polygon {
        fill: transparent;
      }

      path.selected,
      polygon.selected {
        fill: bisque;
      }
    }
    g.graph {
      padding: 1rem;
      polygon {
        fill: rgb(249, 249, 249);
      }
    }
  }

  .de-dependency {
    width: 100%;
    background: rgb(249, 249, 249);
    border-radius: 0.25rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
    padding: 2rem;

    > * {
      margin-bottom: 2rem;
    }
  }

  &-title-bottom {
    margin: 1rem 0 0 0;
    display: inline-block;
  }

  &-workflow-popup {
    a.github {
      color: darkblue;
      text-decoration: none;
      display: inline-block;

      &:hover {
        color: darkred;
      }
    }

    .MuiPaper-root {
      padding-bottom: 10px;
    }

    .required {
      padding-top: 10px;
    }
    .screenshot {
      padding-top: 10px;
    }

    .MuiList-root {
      list-style-type: disc;
    }

    .MuiListItem-root {
      /* display: list-item; */
      /* list-style: disc; */
      padding: 0 0 0 20px;
    }

    .MuiListItemText-root {
      display: list-item;
      list-style: disc;
    }
  }

  .filter-bu {
    margin-left: 10px;

    .search-field {
      width: 400px;
    }
  }

  .bu-count {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    padding: 0 16px;

    span {
      color: #5757f1;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .bu-reload {
    vertical-align: middle;
    color: darkgray;
    padding: 0 8px 0 8px;
  }

  & .audits-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      height: 45px;
    }
  }

  & .audits-title {
    display: flex;
    align-items: flex-end;
    gap: 0.2rem;
    font-size: 18px;
    color: #00396b;

    &__icon {
      font-size: 2rem;
      color: #00396b;
    }
  }

  button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    &.refresh-list {
      padding: 0.2rem;
      transform: scale(1.2);
    }
  }

  .rdt_TableRow {
    &:nth-child(odd) {
      background-color: #55b0ff0d;
      color: #00396b;
    }

    &:nth-child(even) {
      color: #00396b;
    }

    &:hover {
      background-color: #2a9cff17;
      color: #0070d2;
      font-weight: bold;
    }
  }

  .rdt_Table .rdt_TableCell {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;

    div:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // line-height: 4;

      &:hover {
        white-space: nowrap;
        /* overflow: auto; */
        z-index: 1;
        border: thin solid lightgray;
        background-color: white;
        opacity: 1;
        position: absolute;
        left: 0;
        right: 0;
        text-align: right;
        font-weight: bold;
        text-overflow: unset;
        /* width: 100%; */

        padding: 12px 5px;
        line-height: 0.5;
      }
    }
  }

  .rdt_TableHeadRow {
    border: none;
  }

  .expanded-row {
    font-size: smaller;

    td {
      &.label {
        text-align: right;
      }

      .value {
        font-weight: bold;
        padding-left: 10px;
      }
    }
  }

  .bu-list-item {
    .bu-parent {
      font-size: smaller;
      text-align: right;
      color: grey;
    }

    .bu-mid {
      font-size: 12px;
      color: #666666;

      &-text {
        color: #666666 !important;

        button {
          pointer-events: auto;
        }
      }
    }

    .bu-repository {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-size: smaller;
      color: #666;

      a {
        color: rgb(87, 87, 241);
        pointer-events: auto;
      }

      .search-unmatched {
        color: #00396b !important;
      }

      svg {
        font-size: 20px;
      }

      a:hover {
        color: darkred;
      }
    }

    .bu-lastSyncDate {
      font-size: smaller;

      &-text {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        color: #666 !important;
      }

      .value {
        color: #666 !important;
      }

      &.overdue {
        color: darkred;
      }

      h6,
      span {
      }

      button {
        color: rgb(87, 87, 241);
        padding: 4px;
        transform: scale(0.8);
        transform-origin: left;
        pointer-events: auto;

        &:hover {
          color: #0070d2;
        }
      }
    }

    .MuiGrid-root {
      -ms-user-select: text !important;
      -webkit-user-select: text !important;
      user-select: text !important;
    }

    .search-match {
      color: indianred !important;
      font-weight: bold;
    }

    .search-unmatched {
      color: #666 !important;
    }

    a {
      font-weight: bold;
      color: #666;
      text-decoration: none;
      line-height: 1.2;
    }

    .bu-name {
      font-weight: bold;
      color: #00396b;
      font-size: 14px;
      min-width: max-content;
    }

    .bu-link {
      a {
        color: #0070d2;

        &:hover {
          color: darkred;
          cursor: pointer;
        }
      }
    }

    .audit-item,
    .audit-item-text {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    .audit-item {
      &-text {
        a {
          display: flex;
          align-items: center;
          gap: 0.3rem;
        }
      }

      &-icon {
        color: #666;
        font-size: 20px;
      }

      &-title {
        font-size: 15px;
      }
    }

    &:nth-child(odd) .MuiAccordion-root {
      background-color: whitesmoke;
    }

    .MuiAccordion-root {
      border: solid thin lightgray;

      &:hover {
        background-color: rgb(243, 243, 252);
        border-color: #666;
      }

      .bu-accordion-details {
        margin: -10px 0 0 55px;

        ul {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
        }

        a {
          color: #0070d2;

          &:hover {
            color: darkred;
          }
        }
      }

      .bu-accordion-summary {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  .bu-lastSyncDate {
    button {
      color: rgb(87, 87, 241);
      padding: 4px;
      transform: scale(0.8);
      transform-origin: left;

      :hover {
        color: #0070d2;
      }
    }
  }
}

.bu-details-seperator {
  margin: 10px 0 20px 0;
  border-top: thin solid #eee;
  display: block;
  opacity: 0;
}

.data-table-container {
  width: 100%;
  background: rgb(249, 249, 249);
  border-radius: 0.25rem;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
  padding: 2rem;

  header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  & > *,
  nav {
    background: rgb(249, 249, 249) !important;
  }
}

button {
  &.copy-bu-field {
    pointer-events: auto;
    height: 8px;
    padding: 0;

    &:hover {
      color: rgb(12, 12, 114);
    }

    svg {
      width: 0.6em;
      height: 0.6em;
    }

    span {
      padding: 0;
    }
  }
}

.bu-flags {
  font-size: smaller;
  white-space: nowrap;
  color: #666 !important;
  span {
    display: inline-block;
    vertical-align: middle;
  }

  &-box {
    margin-top: 10px;
  }

  .MuiSvgIcon-root {
    vertical-align: middle;
    color: #00396b;
  }

  .MuiSvgIcon-root.disabled {
    vertical-align: middle;
    color: rgb(197, 196, 170);
  }
}

.flash-popper.audits {
  background-color: transparent;
  border: none;
  padding: 0;
}

.flash-popper {
  .popper-box.copied {
    padding: 5px;
    margin-left: 5px;
    border-radius: 0.25rem;
    background-color: white;
    border: solid thin #ccc;
  }
}

.backButtonBox {
  // position: absolute;
  // top: 10px;
  // left: 10px;
  position: fixed;
  top: 57px;
  left: 202px;
  z-index: 11;
  transition: all 0.2s ease;

  &.invalid {
    display: none;
  }

  svg {
    font-size: 48px;
    transition: all 0.2s ease;

    &:hover {
      color: rgb(73, 73, 218);
    }
  }
}

.flash-popper {
  position: absolute;
  z-index: 10000;
}

.search-close-icon {
  color: rgb(87, 87, 241);
  transform: scale(0.8);
  transform-origin: right;
  cursor: pointer;
}

.expand-icon {
  cursor: pointer;
  pointer-events: auto;
  font-size: 36px;
  // color: rgb(87, 87, 241);
  transition: all 0.8s ease;

  &:hover {
    transform: scale(1.04);
    color: rgb(73, 73, 218);
  }
}
