body {
  background: #f3f2ef;
}
.autocomplete-option-item {
  .search-match {
    color: rgb(89, 124, 219) !important;
    font-weight: bold !important;
  }
}

.browse {
  // margin: 20px 0 0 20px;

  .darkHover {
    &:hover {
      color: rgb(12, 12, 114);
    }
  }

  .data-extension {
    width: 100%;
    background: rgb(249, 249, 249);
    border-radius: 0.25rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;
    border: 1px solid #dddbda;
  }

  .DataExtensionHelpers {
    .MuiAccordionSummary-root {
      background-color: #f5f5fa;
      /* background-color: rgb(238, 251, 255); */
    }
  }

  &.dataExtensionPopover {
    overflow: auto;

    .MuiTabs-root + button {
      top: 4px;
    }

    button.MuiTab-root.Mui-selected {
      /* background-color: rgb(238, 251, 255); */
      background-color: #f5f5fa;
      color: #00396b;
      font-weight: bold;
    }

    table {
      border-collapse: collapse;
      font-size: 0.9em;
      min-width: 400px;
      width: 100%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    table thead tr {
      background-color: #0070d2;
      color: #ffffff;
      text-align: left;
    }

    table th,
    table td {
      padding: 12px 15px;
    }

    // table thead th {
    //   white-space: nowrap;
    //   text-align: center;
    //   padding: 0 0 0 10px;
    // }

    table tbody tr {
      border-bottom: 1px solid #dddddd;
    }

    table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    table tbody tr:last-of-type {
      border-bottom: 2px solid rgb(87, 87, 241);
    }

    strong.fieldTitle {
      display: inline-block;
      padding: 10px;
      font-size: 20px;
    }
    // table,
    // table tbody,
    // table tbody td,
    // table tbody tr {
    //   border: none;
    //   border-spacing: 0;
    // }

    tbody {
      tr:nth-child(odd) {
        background-color: rgba(85, 176, 255, 0.0509803922);
      }

      tr {
        border: none !important;
        font-weight: bold;
        color: #00396b;
      }

      tr.pk {
        color: rgb(27, 145, 27);
        font-weight: bold;
      }

      tr.required {
        color: rgb(235 90 90);
        font-weight: bold;
      }

      // td.fieldName {
      //   border-right: solid thin #ddd;
      //   text-align: right;
      //   font-weight: bold;
      //   padding: 0 5px 0 0;
      // }

      // td.fieldPK,
      // td.fieldRequired,
      // td.fieldDefault,
      // td.fieldType {
      //   text-align: center;
      //   padding: 0 0 0 5px;
      // }
    }
  }

  &.DataExtensionHelpers {
    .MuiCollapse-wrapper .DataExtensionHelpers {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .MuiAccordionSummary-content .MuiTypography-root {
      color: rgb(87, 87, 241);
      font-weight: bold;
    }

    pre.de-select-statement {
      text-align: left;
      white-space: pre-wrap;
      /* border-top: solid thin lightgrey; */
      padding: 0 10px 10px 10px;
      margin: 5px 10px 10px 10px;
      max-width: 100%;
    }

    .de-select-statement-buttons {
      min-width: 70px;

      svg {
        font-size: 20px;

        &:hover {
          color: rgb(87, 87, 241) !important;
        }
      }

      a,
      button {
        color: rgb(87, 87, 241);
      }
    }
  }

  .query-grid-item fieldset {
    border: thin solid rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    pre {
      font-size: 14px;
      color: #666666;
    }
  }

  .ruleGroup {
    border: thin solid rgba(0, 0, 0, 0.13);
    background-color: rgba(85, 176, 255, 0.0509803922);
    padding: 1rem;

    button {
      background-color: #0070d2;
      border: 1px solid #0070d2;
      outline: none;
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      padding: 4px 10px;
      border-radius: 0.25rem;
      color: #fff;
      cursor: pointer;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        background-color: #005fb2;
        border-color: #005fb2;
      }
    }

    select,
    input {
      outline: none;
      border: none;
      padding: 9px 12px;
      color: #666;
      border-radius: 0.25rem;
    }
  }

  .de-selection {
    min-width: 600px;
  }

  @media screen and (max-width: 760px) {
    .de-selection {
      min-width: 200px;
    }
  }

  .datable-container {
    margin-bottom: 30px;
    border-radius: 12px;

    &:not(.loading) > div {
      color: #00396b;
      margin: 2px 0 1.2rem;
      // width: -moz-fit-content;
      // width: fit-content;
      max-width: 100%;
    }
  }

  div {
    &.dataextension-details {
      display: flex;
      align-items: center;

      ul {
        padding: 0 0 0 0;
      }

      ul li div,
      ul li {
        padding: 0;
        margin: 0;
        height: 24px;

        strong {
          color: #00396b;
          font-size: 14px;
        }

        span {
          color: #666666;
          font-size: 12px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
        }

        svg {
          width: 0.7em;
          height: 0.7em;
        }
      }
    }
  }

  .MuiGrid-item.orderBy-warning-grid {
    padding-top: 7px;

    .orderBy-warning {
      max-height: 35px;

      .MuiAlert-icon {
        padding: 2px 0 2px 0;
      }

      .MuiAlert-message {
        padding: 2px 0 2px 0;
        overflow: hidden;
      }
    }
  }

  .query-grid-results {
    width: 100%;
  }

  .datable-container {
    // .progress-comp {
    //   color: #222;
    //   width: 100%;
    // }

    .paging-component {
      transform: scale(0.8);
      transform-origin: left;
    }

    &.paging {
      .paging-component {
        border-color: rgb(233, 233, 233);
        color: rgb(233, 233, 233);
      }

      .rdt_Table .rdt_TableCol button,
      .rdt_Table .rdt_TableCol,
      .rdt_Table .rdt_TableCell {
        animation-duration: 0.5s;
        animation-name: pagingProgress;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }

    @keyframes pagingProgress {
      from {
        border-color: #ddd;
        color: rgb(233, 233, 233);
      }

      to {
        border-color: #fff;
        color: rgb(233, 233, 233);
      }
    }

    .paging-component.count-only {
      padding: 2px 0 5px 0;
    }

    .rdt_Table {
      border-collapse: collapse;
      font-size: 0.9em;
      min-width: 400px;
      width: 100%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    .rdt_TableHeadRow > * {
      color: #f3f3f3;
      text-align: left;

      div {
        &:hover {
          color: #ffffff;
          opacity: 1;
        }
      }
    }

    .rdt_TableHeadRow > * {
      padding: 8px 0px !important;
      font-size: 14px;
      font-weight: bold;
      // min-width: 160px;
      // border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    }

    .rdt_TableHeadRow > *:not(:last-child) {
      // border-right: 2px solid rgba(0, 0, 0, 0.05);
    }

    .rdt_TableRow {
      border: none !important;
    }

    .rdt_TableRow > * {
      // padding: 9px 15px;
      // font-size: 14px;
      // border-bottom: 2px solid rgba(0, 0, 0, 0.05);
      padding: 9px 5px 9px 0;
      font-size: 14px;
      // border-bottom: 2px solid rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: flex-end;
    }

    .rdt_TableRow > *:not(:last-child) {
      // border-right: 2px solid rgba(0, 0, 0, 0.05);
    }

    .rdt_TableRow button {
      border-radius: 50%;
      width: 28px;
      height: auto;
    }
  }

  .rdt_TableRow {
    &:nth-child(odd) {
      background-color: #55b0ff0d;
    }

    &:hover {
      background-color: rgb(243, 243, 252);
      color: rgb(10, 9, 58);
      font-weight: bold;
    }
  }

  .rdt_Table {
    .rdt_TableCol {
      border: 1px solid rgb(221 221 221 / 15%);
      padding: 0;

      button {
        min-width: 0 !important;
        text-transform: none;
        color: #00396b;
        font-weight: bold;
        font-size: 10pt;

        font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;

        span.MuiButton-endIcon {
          margin: 0;
        }
      }

      &[data-column-id$='_$pk'],
      &.pk {
        background-color: rgb(244, 255, 244);
      }

      &[data-column-id$='_$req'],
      &.req {
        background-color: rgb(255, 244, 244);
      }
    }

    .rdt_TableCell {
      border: 1px solid rgb(221 221 221 / 15%);
      padding: 0 5px 0 5px;
      font-size: 9pt;
      font-weight: bold;
      font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;

      .i-cell.selectable {
        font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
      }

      div:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover .i-cell.selectable {
        -webkit-user-select: all;
        user-select: all;
      }

      // &[data-column-id$='_$pk'],
      // &.pk {
      //   background-color: rgb(244, 255, 244);
      // }

      // &[data-column-id$='_$req'],
      // &.req {
      //   background-color: rgb(255, 244, 244);
      // }
    }
  }

  .de-selection {
    input {
      color: #666666;
    }
    fieldset {
      border-color: #00396b !important;
    }

    label {
      color: #666666;
    }
    &.selected {
      fieldset {
        border-color: rgb(27 145 27) !important;
      }

      input {
        color: rgb(27 145 27) !important;
        font-weight: bold;
      }
    }

    &.valid {
      input {
        color: rgb(89, 124, 219);
        font-weight: bold;
      }
    }

    &.busy input {
      background-color: rgb(228, 228, 228);
      color: rgb(155, 155, 155);
    }

    &.invalid input {
      background-color: rgb(255, 230, 230);
      color: rgb(155, 155, 155);
    }
  }

  .sqlQuery.invalid {
    color: rgb(161, 18, 18);
  }

  .custom-value-editor {
    display: inline-block;
  }

  .rule-value {
    width: 400px;
  }

  .custom-value-editor.invalid input,
  .custom-value-editor.invalid select,
  .custom-value-editor.invalid option {
    background-color: rgb(255, 230, 230);
  }

  &.download-panel {
    margin: 0;
    padding: 22px 12px 5px 12px;
    border: thin solid #666;

    .lookup-download-warning p:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.DataExtensionDetails {
  .MuiDialog-paper {
    width: 100%;
    height: 100%;
    background: rgb(243 242 239);
  }
}

.xml-template-popper.flash-popper .popper-box.copied {
  padding: 5px;
  margin-left: 5px;
  border-radius: 10px;
  background-color: rgb(236, 255, 247);
  /* border: solid thin #ccc; */
}

.select-sql-statement-popper.flash-popper .popper-box.copied {
  padding: 5px;
  margin-left: 5px;
  border-radius: 10px;
  background-color: rgb(236, 255, 247);
  /* border: solid thin #ccc; */
}

.flash-popper {
  position: absolute;
  z-index: 10000;
}

button {
  &.MuiButton-containedPrimary {
    background-color: #0070d2;
    border: 1px solid #0070d2;
    outline: none;
    font-weight: bold;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    padding: 4px 10px;
    border-radius: 0.25rem;
    color: #fff;
    cursor: pointer;
    box-shadow: none;
    text-transform: initial;

    &:hover {
      background-color: #005fb2;
      border-color: #005fb2;
      box-shadow: none;
    }
  }

  &.MuiButton-textPrimary {
    font-weight: bold;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    padding: 6px 10px;
    border-radius: 0.25rem;
    cursor: pointer;
    text-transform: none;
  }

  &.Mui-disabled {
    border: none;
  }
}

@keyframes pagingProgress {
  from {
    border-color: #ddd;
    color: rgb(233, 233, 233);
  }

  to {
    border-color: #fff;
    color: rgb(233, 233, 233);
  }
}

.rowdata-cell-tooltip {
  font-size: 10pt;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
