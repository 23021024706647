.home {
  display: flex;

  &-container {
    flex: 6;
    width: 10%;
  }

  &-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.main {
  // min-height: 100vh;
  // padding: 2.4rem;
  // display: flex;
  // flex-direction: column;
  // gap: 2rem;
  // position: relative;
  // margin-top: 20px;
  // transition: all 0.2s ease;

  &.appexchange {
    padding: 0 0;

    & > div {
      margin: 0;
    }
  }

  .row {
    display: flex;
    gap: 2rem;
  }
}
