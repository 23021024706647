.business-unit-details {
  margin-top: 20px;

  a {
    text-decoration: none;
    color: darkblue;

    &:hover {
      color: darkred;
    }
  }

  .bu-reload {
    vertical-align: middle;
    color: darkgray;
    padding: 0 8px 0 8px;
  }
}
