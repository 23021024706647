.feedback-dialog {
  .MuiDialog-paper {
    margin-top: 90px;
    margin-bottom: 45px;
  }
}

.feedback {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  label {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  &__title {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    z-index: 100;
  }

  &__options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    &__radio label {
      position: relative;
      width: 70px;
      height: 70px;
      transition: all 0.4s ease;
    }

    &__radio svg {
      color: rgb(0, 57, 107);
      width: 100%;
      height: 100%;
    }

    &__radio svg path,
    &__radio svg circle {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #ebf1f5;
      opacity: 0.5;
    }

    &__radio label,
    &__radio input {
      cursor: pointer;
    }

    &__radio input {
      visibility: hidden;
    }

    &__radio {
      &__checked {
        transform: scale(1.6);
        opacity: 1;

        svg path,
        svg circle {
          opacity: 1;
        }
      }
    }
    &__checkbox {
      display: flex;
      gap: 1rem;
      position: relative;

      &__label {
        display: flex;
        flex-direction: row !important;
        gap: 0.2rem !important;
        // align-items: center !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        width: 225px;

        span {
          opacity: 0.5;
          transform: translateY(-25px);
        }
      }
    }
  }

  input,
  textarea {
    border: 2px solid #dbdada;
    border-radius: 8px;
    display: block;
    padding: 0.5rem;
  }
  input[type='checkbox'] {
    accent-color: rgb(0, 57, 107);
  }

  textarea {
    height: 100px;
  }

  textarea:focus-visible {
    outline: none;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span {
      cursor: pointer;
    }

    button[type='submit'] {
      width: 100px;
    }
  }
  &__anonymous__warning {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 11px;
    line-height: 1;
    position: absolute;
    right: 0;
    top: -2px;
    width: 290px;

    // @media screen and (max-width: 490px) {
    //   position: static;
    //   width: auto;
    // }
  }
}

.feedback-icon {
  position: fixed !important;
  bottom: 50px;
  right: 30px;
  padding: 1rem;
  background: #f3f3f3;
  color: rgb(0, 57, 107) !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
  }

  svg {
    transition: all 0.2s ease;
    font-size: 2rem;
  }
}

.MuiButton-outlinedPrimary {
  color: #0070d2 !important;
  border-color: #0070d2 !important;
  font-weight: bold !important;
  text-transform: none !important;
  line-height: 1.5 !important;

  &:hover {
    background-color: rgba(87, 87, 241, 0.04) !important;
  }
}
